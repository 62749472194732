<div *ngIf="card" class="card" [ngClass]="{ mapView: isMapView }">
  <div class="image">
    <img [src]="card.image?.path || 'https://placehold.co/600x400/png'" alt="Apartment Image" />
  </div>
  <div class="content">
    <div class="title">
      <a class="applicant-name" [href]="card.accomodationWebPage" target="_blank">{{ card.applicantName }}</a>
    </div>
    <div class="subtitle">{{ card.accomodationType?.name }}</div>
    <div class="rating">{{ accomodationClassText(card.accomodationClass?.name) }}</div>
    <div class="location">{{ card.suburb ? card.suburb.label + ' / ' + card.city : card.city }}</div>
    <div class="badges">
      <div class="badge" *ngFor="let card of card.acceptsCardType">
        <img [src]="getBadgeUrl(card.name)" [alt]="card.name" />
      </div>
    </div>
    <div class="bottom-section" [ngClass]="{ 'only-button': !card.isKpp, mapView: isMapView }">
      <img *ngIf="card.isKpp" class="kpp-image" src="/assets/images/kpp.webp" alt="KPP" />
      <div class="button">
        <a [href]="card.accomodationWebPage" target="_blank">LEFOGLALOM</a>
      </div>
    </div>
  </div>
</div>
